import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import SocialMediaImg from "../../components/images/web/socialmedia"

export default function SocialMedia() {
    return (
        <>
            <Layout>
                <SEO title="Social Media" />
                <Display
                    title="Social Media"
                    description="An active social media presence is essential for any business plan – but be sure to do it right. Talk to us about social profiles that engage, inform & raise your brand awareness."
                    prev="/web/blogs"
                    next="/web/copywriting"
                    image={<SocialMediaImg />}
                />
            </Layout>                
        </>
    )
}
